import {Component, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {MasterDetailService} from "../../../_services/main/master-detail.service";
import {StudentService} from "../../../_services/main/student.service";
import {NbToastrService} from "@nebular/theme";
import {CareerKeyResult, Student, StudentEducation, StudentHigherEducation} from "../../../_models/student";
import {Observable} from "rxjs";
import {debounceTime, map} from "rxjs/operators";
import {AsyncValidationService} from "../../../_services/async-validation.service";
import {EducationDraftComponent} from "./education-draft/education-draft.component";
import {MasterDetail} from "../../../_models/master";
import {HigherEducationDraftComponent} from "./higher-education-draft/higher-education-draft.component";
import {CareerKeyComponent} from "./career-key/career-key.component";
import {ImageCroppedEvent} from "ngx-image-cropper";

@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.component.html',
  styleUrls: ['./student-profile.component.scss']
})
export class StudentProfileComponent implements OnInit {

  studentBasicForm : FormGroup;
  fr_submitted: boolean = false;
  sel_options_gender:any=[];
  selectedGender:any;
  dob:string;

  sbd_loading : false;

  student: Student;
  @ViewChild(EducationDraftComponent,{static:false}) child:EducationDraftComponent;
  @ViewChild(HigherEducationDraftComponent,{static:false}) higher_education_child:HigherEducationDraftComponent;
  @ViewChild(CareerKeyComponent,{static:false}) career_key_child:CareerKeyComponent;


  select_options_dis: MasterDetail[] = [];
  selected_dis: MasterDetail;
  select_options_div: MasterDetail[] = [];
  selected_div: MasterDetail;
  select_options_gsw: MasterDetail[] = [];
  selected_gsw: MasterDetail;

  is_sidebar_education : boolean = false;
  is_sidebar_higher_education : boolean = false;
  draft_education_title: string;
  draft_higher_education_title: string;
  is_education_draft_edit: boolean = false;
  is_higher_education_draft_edit: boolean = false;
  is_career_key_draft_edit: boolean = false;
  draft_education: StudentEducation;
  draft_higher_education: StudentHigherEducation;
  draft_career_key: CareerKeyResult;
  hide_add_new_buttons: boolean = false;
  is_sidebar_career_key: boolean = false;
  chart_data: any;


  public chartOptions = {
    scales: {
      yAxes: [{
        ticks: {
          stepSize: 2,
          beginAtZero: true
        }
      }]
    },
    title : {
      display : true,
      text : 'Career Key Test Result'
    },
    legend : {
      display: false
    }
  };
  imageChangedEvent: any;
  croppedImage: any;
  pps_loading: boolean;

  constructor(
    private formBuilder : FormBuilder,
    private mdService : MasterDetailService,
    private service : StudentService,
    private toastrService : NbToastrService,
    private validateService : AsyncValidationService
    ) {
    this.sel_options_gender = [
      {label: 'Male', value: 'male'},
      {label: 'Female', value: 'female'}
    ];

  }

  ngOnInit() {

    this.initProleDetailsForm();
    this.service.currentStudent.subscribe(
      st => {
        this.student = st;
        this.setDraftValue();
        this.setChartValues();
      }
    );

  }

  // public setDraftStudent(student){
  //   this.student = student;
  //   this.setDraftValue();
  // }

  public enableBasicFormForEdit(){
    this.studentBasicForm.enable({onlySelf:true, emitEvent: true});
    this.studentBasicForm.controls['nic'].disable({onlySelf:true, emitEvent: true});
    this.studentBasicForm.controls['mobile'].disable({onlySelf:true, emitEvent: true});
    this.studentBasicForm.controls['email'].disable({onlySelf:true, emitEvent: true});
    this.hide_add_new_buttons = false;
  }

  public enableBasicFormForNew(){
    this.studentBasicForm.enable({onlySelf:true, emitEvent: true});
    this.studentBasicForm.controls['nic'].enable();
    this.studentBasicForm.controls['mobile'].enable();
    this.studentBasicForm.controls['email'].enable();
    this.studentBasicForm.controls['dob'].enable();
    this.studentBasicForm.controls['district'].enable();
    this.studentBasicForm.controls['division'].enable();
    this.studentBasicForm.controls['gsw'].enable();
    this.hide_add_new_buttons = false;
  }

  setChartValues(){

    this.chart_data = {
      labels: ['R', 'A', 'I', 'S', 'E', 'C'],
      datasets: [
        {
          label: 'Result',
          backgroundColor: '#9CCC65',
          borderColor: '#7CB342',
          data: [
            this.student ? this.student.career_key_result ? this.student.career_key_result.key_r : 0: 0,
            this.student ? this.student.career_key_result ? this.student.career_key_result.key_i : 0: 0,
            this.student ? this.student.career_key_result ? this.student.career_key_result.key_a : 0: 0,
            this.student ? this.student.career_key_result ? this.student.career_key_result.key_s : 0: 0,
            this.student ? this.student.career_key_result ? this.student.career_key_result.key_e : 0: 0,
            this.student ? this.student.career_key_result ? this.student.career_key_result.key_c : 0: 0
          ]
        }
      ]
    };
  }

  get fStudentBasicDetails() { return this.studentBasicForm.controls; }

  initProleDetailsForm(){

    this.studentBasicForm = this.formBuilder.group({
      first_name: ["", Validators.required],
      middle_name: [""],
      last_name: ["", [Validators.required]],
      nic: ["", [Validators.required], this.validateNicViaServer.bind(this)],
      email: ["", [Validators.email],this.validateEmailViaServer.bind(this)],
      mobile: ["", [Validators.required],this.validateMobileViaServer.bind(this)],
      home: ["", ],
      gender: ["", ],
      dob: ["", ],
      address1: ["", ],
      address2: ["", ],
      address3: ["", ],
      guardian_name: ["", ],
      guardian_mobile: ["", ],
      district: ["", [Validators.required]],
      division: ["", [Validators.required]],
      gsw: ["", [Validators.required]],
    });
  }

  public  setDraftValue(){
    // this.studentBasicForm.enable({onlySelf:true, emitEvent: true});
    this.studentBasicForm.setValue({
      first_name : this.student ? this.student.first_name : '',
      middle_name : this.student ? this.student.middle_name : '',
      last_name : this.student ? this.student.last_name : '',
      nic : this.student ? this.student.nic : '',
      email : this.student ? this.student.email : '',
      mobile : this.student ? this.student.mobile : '',
      home : this.student ? this.student.home : '',
      gender : null,
      dob : this.student ? this.student.dob : '',
      address1 : this.student ? this.student.address1 : '',
      address2 : this.student ? this.student.address2 : '',
      address3 : this.student ? this.student.address3 : '',
      guardian_name : this.student ? this.student.guardian_name : '',
      guardian_mobile : this.student ? this.student.guardian_mobile : '',
      district : null,
      division : null,
      gsw : null,
    });
    this.croppedImage = this.student ? this.student.image : null;
    this.selected_dis = null;
    this.selected_div = null;
    this.selected_gsw = null;
    if(this.student){
      this.selected_dis = this.student.district;

      this.selected_div = this.student.division;

      this.selected_gsw =  this.student.gsw;

      this.selectedGender = this.student.gender;
    }

    if(this.student){
      this.studentBasicForm.disable({onlySelf:true, emitEvent: true});
      this.hide_add_new_buttons = true;
    }
  }

  searchDis($event: any) {
    this.mdService.searchByString($event.query, 'DIS',null)
      .subscribe(
        data=>{
          this.selected_gsw = null;
          this.selected_div = null;
          this.select_options_dis = data
        }
      )
  }

  searchDiv($event: any) {
    this.mdService.searchByString($event.query, 'DIV', this.selected_dis)
      .subscribe(
        data=>{
          this.selected_gsw = null;
          this.select_options_div = data;
        }

      )
  }

  searchGsw($event: any) {
    this.mdService.searchByString($event.query, 'GSW', this.selected_div)
      .subscribe(
        data=>{
          this.select_options_gsw = data
        }
      )
  }

  studentBasicDetailsFormSubmit(){
    if(!this.student){
      this.basicDetailsSave();
    }
    else{
      this.basicDetailsUpdate();
    }
  }

  basicDetailsUpdate(){
    this.fr_submitted = true;
    this.service.updateBasicDetails(this.student.id,this.studentBasicForm.value)
      .subscribe(
        data=>{
          this.toastrService.show(
            'Successfully save the basic details.',
            'Success',
            {status :'success'}
          );

          this.student = data;
          this.service.changeStudent(this.student);
        },
        error=>{
          this.toastrService.show(
            'Something went wrong.',
            'Error',
            {status :'danger'}
          );
        }

      )
  }

  basicDetailsSave(){
    this.fr_submitted = true;
    this.service.saveBasicDetails(this.studentBasicForm.value)
      .subscribe(
        data=>{
          this.toastrService.show(
            'Successfully save the basic details.',
            'Success',
            {status :'success'}
          );

          this.student = data;
          this.service.changeStudent(this.student);
        },
        error=>{
          this.toastrService.show(
            'Something went wrong.',
            'Error',
            {status :'danger'}
          );
        }

      )
  }

  initEducationDraftSideBar(education: StudentEducation, is_new : boolean) {

    this.draft_education = education;
    if(!is_new){
      this.is_education_draft_edit = true;
      let ti = education.level == 'ol' ? 'O/L Examination '+education.year : 'A/L Examination '+education.year ;
      this.draft_education_title = "Edit Education  [ "+ti+" ]";
    }
    else{
      this.is_education_draft_edit = false;
      this.draft_education_title = 'Add New';
    }

    this.toggleEducationDraft();
  }

  sideBarEducationShowEvent() {
    this.child.setValues();
  }

  sideBarEducationCloseEvent() {
    this.child.resetForms();
  }

  toggleEducationDraft() {
    this.is_sidebar_education = !this.is_sidebar_education;
  }

  toggleHigherEducationDraft() {
    this.is_sidebar_higher_education = !this.is_sidebar_higher_education;
  }

  educationSave($event) {
    this.toggleEducationDraft();
    this.student = $event;
  }

  validateNicViaServer({value}: AbstractControl): Observable<ValidationErrors | null> {
    return this.validateService.isStudentNICExists(value)
      .pipe(debounceTime(500), map((nicExists: boolean) => {
        if (nicExists) {
          return {
            nameExists: true
          };
        }
        return null;
      }));
  }

  validateMobileViaServer({value}: AbstractControl): Observable<ValidationErrors | null> {
    return this.validateService.isStudentMobileExists(value)
      .pipe(debounceTime(500), map((mobileExists: boolean) => {
        if (mobileExists) {
          return {
            nameExists: true
          };
        }
        return null;
      }));
  }

  validateEmailViaServer({value}: AbstractControl): Observable<ValidationErrors | null> {
    return this.validateService.isStudentEmailExists(value)
      .pipe(debounceTime(500), map((emailExists: boolean) => {
        if (emailExists) {
          return {
            nameExists: true
          };
        }
        return null;
      }));
  }

  deleteEducation(student_education) {
    this.service.deleteEducationDetails(this.student.id, student_education.id)
      .subscribe(
        data=>{
          this.toastrService.show(
            'Successfully delete the education details.',
            'Success',
            {status :'success'}
          );
          this.service.get(this.student.id)
            .subscribe(
              data1=>{
                this.student = data1;
              });
        },
        error => {
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        }
      )
  }

  initHigherEducationDraftSideBar(higher_education : StudentHigherEducation, is_new: boolean) {
    this.draft_higher_education = higher_education;
    if(!is_new){
      this.is_higher_education_draft_edit = true;
      this.draft_higher_education_title = "Edit Higher Education";
    }
    else{
      this.is_higher_education_draft_edit = false;
      this.draft_higher_education_title = 'Add New';
    }

    this.toggleHigherEducationDraft();
  }

  sideBarHigherEducationCloseEvent() {
    this.child.resetForms();
  }

  sideBarHigherEducationShowEvent() {
    this.higher_education_child.setValues()
  }

  higherEducationSave($event: any) {
    this.toggleHigherEducationDraft();
    this.student = $event;
  }

  deleteHigherEducation(edu: StudentHigherEducation) {
    this.service.deleteHigherEducationDetails(this.student.id, edu.id)
      .subscribe(
        data=>{
          this.toastrService.show(
            'Successfully delete the higher education details.',
            'Success',
            {status :'success'}
          );
          this.service.get(this.student.id)
            .subscribe(
              data1=>{
                this.student = data1;
                this.service.changeStudent(this.student);
              });
        },
        error => {
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        }
      )
  }

  sideBarCareerKeyCloseEvent() {
    this.career_key_child.resetForms();
    this.setChartValues();
  }

  sideBarCareerKeyShowEvent() {
    this.career_key_child.setValues();
  }

  careerKeySave($event: any) {
    this.student = $event;
    this.toggleCareerKeyResultDraft();
  }

  toggleCareerKeyResultDraft() {
    this.is_sidebar_career_key = !this.is_sidebar_career_key;
  }

  initCareerDraftSideBar() {
    this.draft_career_key = this.student.career_key_result;
    this.toggleCareerKeyResultDraft();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    // this.setValueProfilePic();
  }
  imageLoaded() {
    // console.log("oooooooookkkkkkkkkkkkk", this.student.image);
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // console.log("Faild", this.student.image);
    // show message
  }

  profilePictureSubmit() {
    this.pps_loading = true;
    this.service.saveImage(this.student.id, {image : this.croppedImage})
      .subscribe(
        data=>{
          this.student.image = data;
          this.service.changeStudent(this.student);
          this.pps_loading = false;
          this.toastrService.show(
            'Successfully saved',
            'Success',
            {status :'success'}
          );
        },
        error=>{
          this.pps_loading = false;
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );

        });
  }
}
