import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {API_URL} from "../_shared/API_URL.const";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {


  private profileSource  = new BehaviorSubject(null);
  currentProfileSource = this.profileSource.asObservable();

  constructor(
    private api : ApiService
  ) {
  }

  setProfile(){
    this.getProfile()
      .subscribe(data=>{
        this.profileSource.next(data);
      })
  }

  getProfile(){
    return this.api.get(API_URL.PROFILE.GET);
  }

  savePersonalDetails(param) : Observable<any>{
    // return this.api.get(API_URL.PROFILE.GET);
    return this.api.patch(API_URL.PROFILE.DETAILS, param);
  }

  saveProfilePicture(param):Observable<any>{
    return this.api.patch(API_URL.PROFILE.PIC, param);
  }

  resetPassword(param){
    return this.api.post(API_URL.PROFILE.RESET_PASSWORD, param);
  }

  logoutFromServer() : Observable<any>{
    return this.api.post(API_URL.AUTH.LOGOUT, {});
  }
}
