import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {Permission, Role, User} from "../../../_models/user-mgt";
import {NbStepperComponent, NbToastrService} from "@nebular/theme";
import {RoleService} from "../../../_services/main/role.service";
import {MENU_ITEMS} from "../../MENU_ITEMS.const";
import {Observable} from "rxjs";
import {debounceTime, map} from "rxjs/operators";
import {AsyncValidationService} from "../../../_services/async-validation.service";

@Component({
  selector: 'app-role-draft',
  templateUrl: './role-draft.component.html',
  styleUrls: ['./role-draft.component.scss']
})
export class RoleDraftComponent implements OnInit {

  roleForm : FormGroup;
  assignPermissionsForm : FormGroup;

  @Input() role: Role;
  @Input() is_edit: boolean;
  @Output() saveEvent = new EventEmitter<any>();

  @ViewChild('stepper', {static:false}) stepper : NbStepperComponent;
  fr_submitted: boolean = false;

  menu_groups = MENU_ITEMS;
  all_permissions : Permission[] = [];
  selected_permissions_ids : number[] = [];
  rd_loading: boolean = false;

  constructor(
    private formBuilder : FormBuilder,
    private service : RoleService,
    private toastrService : NbToastrService,
    private validateService : AsyncValidationService,
  ) {
    this.service.publicGetAllPermissions()
      .subscribe(
        data=>{
          this.all_permissions = data;
        }
      );


  }

  ngOnInit() {
    this.initProleDetailsForm();
    this.initAssignPermissionsForm();
  }

  setDraftValues(){
    this.service.publicGetPermissions(this.role.id)
      .subscribe(
        data=>{
          this.selected_permissions_ids = [];
          for(let p of data.permissions){
            this.selected_permissions_ids.push(p.id);
          }
          this.assignPermissionsForm.setValue({
            permissions : this.selected_permissions_ids
          })
        }
      );
    this.roleForm.setValue({
      name: this.is_edit ? '' : this.role.name ,
      display_name: this.role.display_name,
      description: this.role.description
    });

    if(this.is_edit){
      this.roleForm.controls["name"].disable();
      this.roleForm.controls["name"].reset(this.role.name);
    }
    else{
      this.roleForm.controls["name"].enable();
    }


  }

  get fRoleDetails() { return this.roleForm.controls; }
  get fAssignPermissions() { return this.assignPermissionsForm.controls; }


  initProleDetailsForm(){

    this.roleForm = this.formBuilder.group({
      name: ["", Validators.required,  this.validateRoleNameViaServer.bind(this)],
      display_name: ["", Validators.required],
      description: ["", [Validators.required]]
    });
  }

  initAssignPermissionsForm(){

    this.assignPermissionsForm = this.formBuilder.group({
      permissions: ["", Validators.required]
    });
  }



  resetForms() {
    this.roleForm.reset();
    this.assignPermissionsForm.reset();
    this.stepper.reset();
  }


  roleDetailsFormSubmit() {

  }

  assignRolesFormSubmit() {

    // console.log("FFFFFFFFFFFFFFFF", this.assignPermissionsForm.value);
    if(this.is_edit){
      this.edit();
    }
    else{
      this.save();
    }
  }

  getAllPermissions(){

  }

  getPermissionsInGroup(group){
    let return_permissions_array : Permission[] = [] ;
    for(let p of this.all_permissions){
      let per_arr = p.name.split('-');
      if(per_arr[0]==group){
        return_permissions_array.push(p);
      }
    }
    return return_permissions_array;
  }

  private edit() {

    this.rd_loading = true;
    this.service.update(this.role.id, this.roleForm.value)
      .subscribe(data=>{

          this.rd_loading = false;
          if(data){
            this.toastrService.show(
              'Successfully saved',
              'Success',
              {status :'success'}
            );
            this.assignPermissions(data);
          }
        },
        error=>{
          this.rd_loading = false;
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        });
  }

  private assignPermissions(role : Role) {

    this.rd_loading = true;
    this.service.assignPermissions(role, this.assignPermissionsForm.value)
      .subscribe(
        data=>{

          this.rd_loading = false;
          this.stepper.reset();
          role['permissions']=data;
          this.saveEvent.emit(role);


          this.toastrService.show(
            'Successfully assigned the permissions',
            'Success',
            {status :'success'}
          );
        },
        error => {
          this.rd_loading = false;
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        }
      )
  }

  private save() {
    this.rd_loading = true;
    this.service.save(this.roleForm.value)
      .subscribe(data=>{
          this.rd_loading = false;
          if(data){

            this.toastrService.show(
              'Successfully saved',
              'Success',
              {status :'success'}
            );
            this.assignPermissions(data);
          }
        },
        error=>{
          this.rd_loading = false;
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        });
  }


  validateRoleNameViaServer({value}: AbstractControl): Observable<ValidationErrors | null> {
    return this.validateService.isRoleNameExists(value)
      .pipe(debounceTime(500), map((emailExists: boolean) => {
        if (emailExists) {
          return {
            nameExists: true
          };
        }
        return null;
      }));
  }
}
