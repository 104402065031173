import { Injectable } from '@angular/core';
import {ApiService} from "../api.service";
import {Observable} from "rxjs";
import {Role} from "../../_models/user-mgt";
import {API_URL} from "../../_shared/API_URL.const";
import {MasterDetail, MasterHeader} from "../../_models/master";

@Injectable({
  providedIn: 'root'
})
export class SelectService {

  constructor(
    private api : ApiService
  ){}

  roles() : Observable<Role[]>{
    return this.api.get(API_URL.SELECT.ROLES);
  }

  masterHeaders() : Observable<MasterHeader[]>{
    return this.api.get(API_URL.SELECT.MASTER_HEADER);
  }

  masterParents(id) : Observable<MasterDetail[]>{
    return this.api.get(API_URL.SELECT.MASTER_HEADER_DETAILS+'/'+id+'/master-details');
  }
}
