import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Student, StudentEducation, StudentEducationSubject} from "../../../../_models/student";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MasterDetailService} from "../../../../_services/main/master-detail.service";
import {NbToastrService} from "@nebular/theme";
import {StudentService} from "../../../../_services/main/student.service";
import {MasterDetail} from "../../../../_models/master";

@Component({
  selector: 'app-education-draft',
  templateUrl: './education-draft.component.html',
  styleUrls: ['./education-draft.component.scss']
})
export class EducationDraftComponent implements OnInit {
  @Input() education : StudentEducation;
  @Input() student : Student;
  @Input() is_edit : boolean = false;
  @Output() saveEvent = new EventEmitter<any>();

  studentExamForm : FormGroup;
  selectedLevel: any;
  sel_options_level: any[];
  fr_submitted = false;

  rd_loading : boolean = false;

  select_options_stream: MasterDetail[] = [];
  selected_stream: MasterDetail;
  is_private: boolean = false;
  selected_dis: MasterDetail;
  select_options_dis: MasterDetail[] = [];
  selected_sub: MasterDetail;
  select_options_sub: MasterDetail[] = [];
  selected_scl: MasterDetail;
  select_options_scl: MasterDetail[] = [];
  selected_mdum: MasterDetail;
  select_options_mdum: MasterDetail[] = [];
  selected_grd: MasterDetail;
  select_options_grd: MasterDetail[] = [];


  constructor(
    private formBuilder : FormBuilder,
    private mdService : MasterDetailService,
    private toastrService : NbToastrService,
    private service : StudentService
    ) {
    this.sel_options_level = [
      {label: 'O/L Examination', value: 'ol'},
      {label: 'A/L Examination', value: 'al'}
    ];
  }

  ngOnInit() {
    this.initForm();
  }

  get fStudentExamDetails() { return this.studentExamForm.controls; }
  get fStudentExamSubjects() { return this.fStudentExamDetails.subjects as FormArray; }

  initForm(){
    this.studentExamForm = this.formBuilder.group({
      level : ['', Validators.required],
      stream : ['',],
      year : ['', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
      ]],
      index : ['', Validators.required],
      is_private : [''],
      district : [''],
      school : [''],
      subjects : new FormArray([])
    });
  }

  setValues(){

    if(this.education){
      this.studentExamForm.reset({
        year : this.education.year,
        index : this.education.index,
        level : this.education.level,
        is_private : this.education.is_private,
      });
      this.is_private = this.education.is_private ? true : false;
      // this.fStudentExamSubjects = this.education.subjects
      this.fStudentExamSubjects.clear();
      for(let s of this.education.subjects){
        this.addSubjectForm(s);
      }
    }


    if(this.education){
      this.selected_stream = this.education.stream ? this.education.stream : null;
      this.selected_dis = this.education.stream ? this.education.district : null;
      this.selected_scl = this.education.stream ? this.education.school : null;
    }
  }

  resetForms() {
    this.studentExamForm.reset();
    this.fr_submitted = false;
    this.fStudentExamSubjects.reset();
  }

  addSubjectForm(subject : StudentEducationSubject = null) {
    this.fStudentExamSubjects.push(this.formBuilder.group({
      subject : [subject ? subject.subject : null , Validators.required],
      grade : [subject ? subject.grade : null , Validators.required],
      medium : [subject ? subject.medium : null , Validators.required],
    }));
  }

  removeSubjectForm(i){
    this.fStudentExamSubjects.removeAt(i);
  }

  searchStream($event: any) {
    this.mdService.searchByString($event.query, 'STRM',null)
      .subscribe(
        data=>{
          this.select_options_stream = data
        }
      )
  }

  searchDis($event: any) {
    this.mdService.searchByString($event.query, 'DIS',null)
      .subscribe(
        data=>{
          this.selected_scl = null;
          this.select_options_dis = data;
        }
      )
  }

  searchScl($event: any) {
    this.mdService.searchByString($event.query, 'SCL',null)
      .subscribe(
        data=>{
          this.select_options_scl = data;
        }
      )
  }

  searchSub($event: any) {
    this.mdService.searchByString($event.query, 'SUB',null)
      .subscribe(
        data=>{
          this.select_options_sub = data;
        }
      )
  }

  searchMedium($event: any) {
    this.mdService.searchByString($event.query, 'MDUM',null)
      .subscribe(
        data=>{
          this.select_options_mdum = data;
        }
      )
  }

  searchGrd($event: any) {
    this.mdService.searchByString($event.query, 'GRD',null)
      .subscribe(
        data=>{
          this.select_options_grd = data;
        }
      )
  }

  draftDetailsFormSubmit() {
    if(this.is_edit){
      this.draftDetailsUpdate();
    }
    else{
      this.draftDetailsSave();
    }
  }

  draftDetailsSave(){

    this.rd_loading = true;
    this.service.saveEducationDetails(this.student.id, this.studentExamForm.value)
      .subscribe(data=>{
          this.rd_loading = false;
          if(data){
            this.rd_loading = false;
            this.saveEvent.emit(data);
            this.studentExamForm.reset();
            this.toastrService.show(
              'Successfully saved',
              'Success',
              {status :'success'}
            );
          }
        },
        error=>{
          this.rd_loading = false;
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        });
  }

  draftDetailsUpdate(){

    this.rd_loading = true;
    this.service.editEducationDetails(this.student.id, this.education.id, this.studentExamForm.value)
      .subscribe(data=>{
          this.rd_loading = false;
          if(data){
            this.rd_loading = false;
            this.saveEvent.emit(data);
            this.studentExamForm.reset();
            this.toastrService.show(
              'Successfully saved',
              'Success',
              {status :'success'}
            );
          }
        },
        error=>{
          this.rd_loading = false;
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        });
  }

  changeLevel() {
    if(this.selectedLevel === 'al'){
      this.fStudentExamDetails['level'].setValidators(Validators.required);
    }
    else{
      this.fStudentExamDetails['level'].clearValidators();
    }
  }
}
