import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {API_URL} from "../../_shared/API_URL.const";
import {Role, User} from "../../_models/user-mgt";
import {ApiService} from "../api.service";
import {MasterDetail} from "../../_models/master";

@Injectable({
  providedIn: 'root'
})
export class MasterDetailService {

  constructor(
    private api : ApiService
  ) { }

  public loadTable(params): Observable<any>{
    return this.api.post(API_URL.MASTER_DETAIL.DATA_TABLE, params);
  }

  update(id: number, value: any[]) : Observable<Role>{
    return  this.api.put(API_URL.MASTER_DETAIL.PUT+"/"+id, value);
  }

  save(value: any[]) : Observable<Role>{
    return  this.api.post(API_URL.MASTER_DETAIL.POST, value);
  }

  searchByString(str:any, header_code:string=null, parent:MasterDetail = null) : Observable<MasterDetail[]>{
    return this.api.post(API_URL.SELECT.MASTER_DETAIL_SEARCH, {'str':str, 'header' : header_code, 'parent':parent});
  }


  public changeStatus(masterDetail : MasterDetail, status) : Observable<User>{
    return this.api.patch(`${API_URL.MASTER_DETAIL.CHANGE_STATUS}/${masterDetail.id}/change-status`, status);
  }

}
