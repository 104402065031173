import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {API_URL} from "../../_shared/API_URL.const";
import {ApiService} from "../api.service";
import {Role} from "../../_models/user-mgt";

@Injectable({
  providedIn: 'root'
})
export class MasterHeaderService {

  constructor(
    private api : ApiService
  ) { }


  public loadTable(params): Observable<any>{
    return this.api.post(API_URL.MASTER_HEADER.DATA_TABLE, params);
  }

  update(id: number, value: any[]) : Observable<Role>{
    return  this.api.put(API_URL.MASTER_HEADER.PUT+"/"+id, value);
  }

  save(value: any[]) : Observable<Role>{
    return  this.api.post(API_URL.MASTER_HEADER.POST, value);
  }
}
