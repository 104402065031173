import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Student} from "../../../../../_models/student";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MasterDetail} from "../../../../../_models/master";
import {MasterDetailService} from "../../../../../_services/main/master-detail.service";
import {StudentService} from "../../../../../_services/main/student.service";
import {NbToastrService} from "@nebular/theme";

@Component({
  selector: 'app-draft-path',
  templateUrl: './draft-path.component.html',
  styleUrls: ['./draft-path.component.scss']
})
export class DraftPathComponent implements OnInit {

  student : Student;
  @Input() is_edit : boolean = false;
  @Output() saveEvent = new EventEmitter<any>();

  studentCourseForm : FormGroup;
  fr_submitted = false;
  sbd_loading = false;

  selected_university : MasterDetail = null;
  select_options_university : MasterDetail[] = [];

  select_options_before_course : any[] = [];
  before_course : any = null;

  selected_course : MasterDetail = null;
  select_options_course : MasterDetail[] = [];

  constructor(
    private mdService : MasterDetailService,
    private formBuilder : FormBuilder,
    private studentService : StudentService,
    private toastrService : NbToastrService,
  ) {
    this.loadBeforeCourses();
  }

  ngOnInit() {
    this.initForm();
  }

  get fStudentHEDetails() { return this.studentCourseForm.controls; }

  loadBeforeCourses(){
    this.studentService.currentStudent.subscribe(st=>{
      this.student = st;
      this.select_options_before_course = [];
      if(this.student){
        if(this.student.higher_educations){
          for(let h of this.student.higher_educations){
            this.select_options_before_course.push(
              { label: `${h.course.name} in ${h.university.code}`, value: h }
            )
          }
        }
      }

      if(this.is_edit){
        this.before_course = { label: `${this.before_course.course.name} in ${this.before_course.university.code}`, value: this.before_course }
      }
    });
  }

  searchCourse($event: any) {
    this.mdService.searchByString($event.query, 'UNI-CRS',this.selected_university)
      .subscribe(
        data=>{
          this.select_options_course = data;
        }
      );
  }

  searchUniversity($event: any) {
    this.mdService.searchByString($event.query, 'UNI',null)
      .subscribe(
        data=>{
          this.selected_course = null;
          this.select_options_university = data
        }
      );
  }

  initForm(){
    this.studentCourseForm = this.formBuilder.group({
      before_course : [''],
      university : ['', Validators.required],
      course : ['', Validators.required]
    });
  }

  studentHigherEducationFormSubmit() {
    if(this.is_edit){
      this.edit();
    }
    else{
      this.save();
    }
  }

  private edit() {

    // this.sbd_loading = true;
    // this.studentService.editHigherEducationDetails(this.role.id, this.roleForm.value)
    //   .subscribe(data=>{
    //
    //       this.sbd_loading = false;
    //       if(data){
    //         this.toastrService.show(
    //           'Successfully saved',
    //           'Success',
    //           {status :'success'}
    //         );
    //         this.assignPermissions(data);
    //       }
    //     },
    //     error=>{
    //       this.rd_loading = false;
    //       this.toastrService.show(
    //         'Something went wrong, please try again.',
    //         'Error',
    //         {status :'danger'}
    //       );
    //     });
  }

  private save() {
    this.sbd_loading = true;
    this.studentService.saveHigherEducationDetails(this.student.id, this.studentCourseForm.value)
      .subscribe(data=>{
        this.saveEvent.emit(true);
          this.sbd_loading = false;
          if(data){

            this.toastrService.show(
              'Successfully saved',
              'Success',
              {status :'success'}
            );
          }
        },
        error=>{
          this.sbd_loading = false;
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        });
  }
}
