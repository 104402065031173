import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Student, StudentHigherEducation} from "../../../../_models/student";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MasterDetailService} from "../../../../_services/main/master-detail.service";
import {NbToastrService} from "@nebular/theme";
import {StudentService} from "../../../../_services/main/student.service";
import {MasterDetail} from "../../../../_models/master";

@Component({
  selector: 'app-higher-education-draft',
  templateUrl: './higher-education-draft.component.html',
  styleUrls: ['./higher-education-draft.component.scss']
})
export class HigherEducationDraftComponent implements OnInit {

  @Input() higher_education : StudentHigherEducation;
  @Input() student : Student;
  @Input() is_edit : boolean = false;
  @Output() saveEvent = new EventEmitter<any>();

  studentHigherEducationForm : FormGroup;
  fr_submitted = false;
  sbd_loading = false;

  selected_university : MasterDetail = null;
  select_options_university : MasterDetail[] = [];

  selected_course : MasterDetail = null;
  select_options_course : MasterDetail[] = [];

  from : string;
  to : string;
  in_progress: boolean = false;



  constructor(
    private formBuilder : FormBuilder,
    private mdService : MasterDetailService,
    private toastrService : NbToastrService,
    private service : StudentService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  get fStudentHEDetails() { return this.studentHigherEducationForm.controls; }


  searchUniversity($event: any) {
    this.mdService.searchByString($event.query, 'UNI',null)
      .subscribe(
        data=>{
          this.selected_course = null;
          this.select_options_university = data
        }
      );
  }

  initForm(){
    this.studentHigherEducationForm = this.formBuilder.group({
      university : ['', Validators.required],
      course : ['', Validators.required],
      from : ['',
        [Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4)]],
      to : ['',
        [Validators.minLength(4),
        Validators.maxLength(4)]],
      in_progress : ['']
    });
  }

  setValues(){
    if(this.higher_education){
      this.studentHigherEducationForm.reset({
        from : this.higher_education.from,
        to : this.higher_education.to,
        in_progress : this.higher_education.status == 'in-progress',
      });
      this.in_progress = this.higher_education.status == 'in-progress';
    }


    if(this.higher_education){
      this.selected_university = this.higher_education.university ? this.higher_education.university : null;
      this.selected_course = this.higher_education.course ? this.higher_education.course : null;
    }
  }

  resetForms() {
    this.studentHigherEducationForm.reset();
    this.fr_submitted = false;
  }

  searchCourse($event: any) {
    this.mdService.searchByString($event.query, 'UNI-CRS',null)
      .subscribe(
        data=>{
          this.select_options_course = data;
        }
      );
  }

  studentHigherEducationFormSubmit() {
    if(this.is_edit){
      this.draftDetailsUpdate();
    }
    else{
      this.draftDetailsSave();
    }
  }

  private draftDetailsUpdate() {
    this.sbd_loading = true;
    this.service.editHigherEducationDetails(this.student.id, this.higher_education.id, this.studentHigherEducationForm.value)
      .subscribe(data=>{

          this.sbd_loading = false;
          if(data){

            this.sbd_loading = false;
            this.saveEvent.emit(data);
            this.studentHigherEducationForm.reset();

            this.toastrService.show(
              'Successfully saved',
              'Success',
              {status :'success'}
            );
          }
        },
        error=>{
          this.sbd_loading = false;
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        });
  }

  private draftDetailsSave() {
    this.sbd_loading = true;
    this.service.saveHigherEducationDetails(this.student.id, this.studentHigherEducationForm.value)
      .subscribe(data=>{

          this.sbd_loading = false;
          if(data){

            this.sbd_loading = false;
            this.saveEvent.emit(data);
            this.studentHigherEducationForm.reset();

            this.toastrService.show(
              'Successfully saved',
              'Success',
              {status :'success'}
            );
          }
        },
        error=>{
          this.sbd_loading = false;
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        });
  }
}
