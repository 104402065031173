import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Role, User} from "../../../_models/user-mgt";
import {SelectService} from "../../../_services/utils/select.service";
import {Observable} from "rxjs";
import {AsyncValidationService} from "../../../_services/async-validation.service";
import {debounceTime, map} from "rxjs/operators";
import {UserService} from "../../../_services/main/user.service";
import {NbStepperComponent, NbToastrService} from "@nebular/theme";
import {RoleDraftComponent} from "../role-draft/role-draft.component";
import {AuthService} from "../../../_services/auth/auth.service";
import {MasterDetail} from "../../../_models/master";
import {MasterDetailService} from "../../../_services/main/master-detail.service";

@Component({
  selector: 'app-user-draft',
  templateUrl: './user-draft.component.html',
  styleUrls: ['./user-draft.component.scss']
})

export class UserDraftComponent implements OnInit {
  @ViewChild(RoleDraftComponent,{static:false}) child:RoleDraftComponent;

  personalDetailsForm : FormGroup;
  assignRolesForm : FormGroup;

  select_options_dis: MasterDetail[] = [];
  selected_dis: MasterDetail;
  select_options_div: MasterDetail[] = [];
  selected_div: MasterDetail;
  select_options_gsw: MasterDetail[] = [];
  selected_gsw: MasterDetail;

  select_option_roles : any[] = [];
  selectedRoles: any[] = [];
  // user: User = null;
  @Input() user: User;
  @Input() roles: Role[];
  @Input() is_edit: boolean;
  @Output() saveEvent = new EventEmitter<any>();

  @ViewChild('stepper', {static:false}) stepper : NbStepperComponent;


  fpd_submitted = false;
  far_submitted = false;
  role_add_sidebar_display: boolean = false;
  draft_role: any;
  is_draft_edit: any;
  ud_loading : boolean = false;
  constructor(
    private selectService : SelectService,
    private formBuilder: FormBuilder,
    private validateService : AsyncValidationService,
    private service : UserService,
    private toastrService: NbToastrService,
    private auth : AuthService,
    private mdService : MasterDetailService
    ) {
      }

  ngOnInit() {
    this.initPersonalDetailsForm();
    this.initAssignRolesForm();
    this.fpd_submitted = false;
    this.far_submitted = false;
    this.selectService.roles()
      .subscribe(data=>{

        if (data.length > 0) {
          for (let role of data) {
            this.select_option_roles.push({ label: role.display_name, value: role })
          }
        }
        // console.log("select_option_roles 55555555555", this.select_option_roles);
        // this.select_option_roles = data;
      });
  }


  setDraftValues(){
    this.personalDetailsForm.setValue({
      name: this.user.name,
      calling_name: this.user.calling_name,
      email: this.is_edit ? '' : this.user.email,
      password : '',
      address: this.user.address,
      tel1: this.user.tel1,
      tel2: this.user.tel2,
      nic: this.user.nic,
      district : null,
      division : null,
      gsw : null,
    });

    if(this.is_edit){
      this.personalDetailsForm.controls["email"].disable();
      this.personalDetailsForm.controls["email"].reset(this.user.email);
      this.personalDetailsForm.controls["nic"].disable();
      this.personalDetailsForm.controls["nic"].reset(this.user.nic);
      this.personalDetailsForm.controls["password"].disable();
      this.personalDetailsForm.controls["password"].reset("password");

      this.selected_dis = null;
      this.selected_div = null;
      this.selected_gsw = null;
      if(this.user){
        this.selected_dis = this.user.district;

        this.selected_div = this.user.division;

        this.selected_gsw =  this.user.gsw;

      }
    }
    else{
      this.personalDetailsForm.controls["email"].enable();
      this.personalDetailsForm.controls["nic"].enable();
      this.personalDetailsForm.controls["password"].enable();
    }



    this.selectedRoles = [];
    if(this.roles){
      for(let r of this.roles){
        for(let sl of this.select_option_roles){
          if(r.id === sl.value.id){
            this.selectedRoles.push(sl.value);
          }
        }
      }
    }

  }


  searchDis($event: any) {
    this.mdService.searchByString($event.query, 'DIS',null)
      .subscribe(
        data=>{
          this.selected_gsw = null;
          this.selected_div = null;
          this.select_options_dis = data
        }

      )
  }

  searchDiv($event: any) {
    this.mdService.searchByString($event.query, 'DIV', this.selected_dis)
      .subscribe(
        data=>{
          this.selected_gsw = null;
          this.select_options_div = data;
        }

      )
  }

  searchGsw($event: any) {
    this.mdService.searchByString($event.query, 'GSW', this.selected_div)
      .subscribe(
        data=>{
          this.select_options_gsw = data
        }
      )
  }

  resetForms() {
    this.personalDetailsForm.reset();
    this.assignRolesForm.reset();
    this.stepper.reset();
    this.fpd_submitted = false;
    this.far_submitted = false;
  }


  get fPersonalDetails() { return this.personalDetailsForm.controls; }
  get fAssignRoles() { return this.personalDetailsForm.controls; }


  initPersonalDetailsForm(){

    this.personalDetailsForm = this.formBuilder.group({
      name: ["", Validators.required],
      calling_name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email], this.validateEmailViaServer.bind(this)],
      nic: ["", [Validators.required], this.validateNICViaServer.bind(this)],
      password: ["", Validators.required],
      address: [ "", ],
      tel1: ["", Validators.required],
      tel2: ["", ],
      district: ["", [Validators.required]],
      division: ["", [Validators.required]],
      gsw: ["", [Validators.required]],
    });
  }

  initAssignRolesForm(selected_roles : Role[] = []){
    // this.selectedRoles = selected_roles;
    this.assignRolesForm = this.formBuilder.group({
      roles: [[]]
    });
  }

  onSubmit(){

  }

  personalDetailsFormSubmit() {
    this.fpd_submitted = true;
  }

  assignRolesFormSubmit() {
    this.far_submitted = true;
    if(this.is_edit){
      this.editUser();
    }
    else{
      this.saveUser();
    }

  }


  saveUser(){

    this.ud_loading = true;
    this.service.saveUser(this.personalDetailsForm.value)
      .subscribe(data=>{
          this.ud_loading = false;
          if(data){
            // this.toastrService.show(
            //   'Successfully saved',
            //   'Success',
            //   {status :'success'}
            // );
            this.assignRoles(data);
          }
        },
        error=>{
          this.ud_loading = false;
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        });
  }


  editUser(){
    this.ud_loading = true;
    let id = this.is_edit ? this.user.id : null;
    this.service.updateUser(id, this.personalDetailsForm.value)
      .subscribe(data=>{
          this.ud_loading = false;
        if(data){
          this.toastrService.show(
            'Successfully saved',
            'Success',
            {status :'success'}
          );
          this.assignRoles(data);
        }
      },
        error=>{
          this.ud_loading = false;
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        });
  }

  assignRoles(user : User){

    this.service.assignRoles(user,this.assignRolesForm.value)
      .subscribe(data=>{

        this.stepper.reset();
        user['roles']=data;
        this.saveEvent.emit(user);

        this.toastrService.show(
          'Successfully saved',
          'Success',
          {status :'success'}
        );
      });
  }

  validateEmailViaServer({value}: AbstractControl): Observable<ValidationErrors | null> {
    return this.validateService.isEmailExists(value)
      .pipe(debounceTime(500), map((emailExists: boolean) => {
        if (emailExists) {
          return {
            emailExists: true
          };
        }
        return null;
      }));
  }

  validateNICViaServer({value}: AbstractControl): Observable<ValidationErrors | null> {
    return this.validateService.isEmailExists(value)
      .pipe(debounceTime(500), map((nicExists: boolean) => {
        if (nicExists) {
          return {
            emailExists: true
          };
        }
        return null;
      }));
  }

  addRole() {
    this.toggleDraftRole();
  }

  toggleDraftRole() {
    this.role_add_sidebar_display = !this.role_add_sidebar_display;
  }

  sideBarCloseEvent() {
    this.child.resetForms();
  }

  sideBarShowEvent() {

    this.child.resetForms();
  }

  newRoleSave($event: any) {
    this.select_option_roles.push({ label: $event.display_name, value: $event })
    this.toggleDraftRole();
  }
}
