import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GanttTaskData} from "../../../../../_models/GanttTask";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {GanttService} from "../../../../../_services/main/gantt.service";
import {AuthService} from "../../../../../_services/auth/auth.service";
import {NbIconLibraries, NbToastrService} from "@nebular/theme";
import {MasterDetail} from "../../../../../_models/master";
import {MasterDetailService} from "../../../../../_services/main/master-detail.service";

@Component({
  selector: 'app-child-task-edit',
  templateUrl: './child-task-edit.component.html',
  styleUrls: ['./child-task-edit.component.scss']
})
export class ChildTaskEditComponent implements OnInit {

  @Input() task: GanttTaskData;
  @Input() parent: GanttTaskData;
  @Output() saveEvent = new EventEmitter<any>();

  select_options_problem: MasterDetail[] = [];
  selectedProblem: MasterDetail;
  projectMonitorForm: FormGroup;
  _loading: boolean = false;
  _loading_hold: boolean = false;
  _loading_sold: boolean = false;
  fr_submitted: boolean = false;

  selectedType: number;
  types: any[];

  constructor(
    private formBuilder: FormBuilder,
    private service: GanttService,
    private auth: AuthService,
    private toastrService: NbToastrService,
    private iconLibraries: NbIconLibraries,
    private mdService: MasterDetailService
  ) {
    this.types = [
      {label: 'Done', value: 1, flag: 'no_issue.svg'},
      {label: 'Issue', value: 2, flag: 'issue.svg'}
    ];
  }

  ngOnInit() {
    // console.log("----------------------->>>>>>>", this.task);
    this.initProjectMonitorForm();
  }

  setValues(){
    // console.log("22222222222---------->>>>>>>", this.task);
    this.projectMonitorForm.setValue({
      comment: this.task? this.task.comment : '',
      type: null,
      problem: '',
    });

    if(this.task.type >= 2){
      this.projectMonitorForm.get('problem').clearValidators();
      this.projectMonitorForm.get('type').clearValidators();
      this.projectMonitorForm.get('problem').updateValueAndValidity();
      this.projectMonitorForm.get('type').updateValueAndValidity();
    }
    else{
      this.projectMonitorForm.get('type').setValidators([Validators.required]);
      this.projectMonitorForm.get('type').updateValueAndValidity();
    }
    this.selectedType = this.task.type ? this.task.type : null;
    // this.projectMonitorForm.get('type').reset(2);
    this.projectMonitorForm.get('type').updateValueAndValidity();
    this.selectedProblem = this.task.problem ? this.task.problem : null;
  }


  initProjectMonitorForm(){
    this.projectMonitorForm = this.formBuilder.group({
      type: ['', Validators.required],
      comment: ['', ],
      problem: ['', ],
    });
  }

  get fFormDetails() { return this.projectMonitorForm.controls; }


  projectMonitorFormSubmit(id: number) {
    this.service.monitor(id, this.projectMonitorForm.value)
      .subscribe(data=>{
          this.saveEvent.emit(true);
          this.toastrService.show(
            'Successfully saved',
            'Success',
            {status :'success'}
          );
        },
        error=>{
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        });
  }

  projectHoldFormSubmit(id: number) {
    this.service.hold(id, this.projectMonitorForm.value)
      .subscribe(data=>{
          this.saveEvent.emit(true);
          this.toastrService.show(
            'Successfully saved',
            'Success',
            {status :'success'}
          );
        },
        error=>{
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        });
  }

  projectSoldFormSubmit(id: number) {
    this.service.solve(id, this.projectMonitorForm.value)
      .subscribe(data=>{
          this.saveEvent.emit(true);
          this.toastrService.show(
            'Successfully saved',
            'Success',
            {status :'success'}
          );
        },
        error=>{
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        });
  }

  searchProblem($event: any) {
    this.mdService.searchByString($event.query, 'PROB',null)
      .subscribe(
        data=>{
          this.selectedProblem = null;
          this.select_options_problem = data;
        }
      )
  }

  changeProblem() {
  }

  changeType() {
    if(this.selectedType == 2 ){
      this.projectMonitorForm.get('problem').setValidators([Validators.required]);
      this.projectMonitorForm.get('problem').updateValueAndValidity();
    }
    else {
      this.projectMonitorForm.get('problem').clearValidators();
      this.projectMonitorForm.get('problem').updateValueAndValidity();
    }
  }

}
