import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';


import { AuthService }  from './auth/auth.service';
// import { PARAMETERS } from '@angular/core/src/util/decorators';



@Injectable({
  providedIn: 'root'
})
export class ApiService {


  constructor(
    private http: HttpClient,
    private auth : AuthService,
  ) { }


  public getHeaders():HttpHeaders{
    // console.log("Calling to grtHeaders method.......");
    // console.log("this.auth.isLoggednIn()   ", this.auth.isLoggednIn());
    if(this.auth.isLoggednIn()){
      return new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Bearer': this.auth.getAuthToken()
      })
    }
    else{
      return  new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type',
      });
    }
  }



  public get(url, param={}) : Observable<any>{


    let httpPraram = new HttpParams();
    for(var x in param){
      httpPraram = httpPraram.append(x,param[x]);
    }


    return this.http.get(url, { headers : this.getHeaders()})
      .pipe(
        // retry(3),
        // catchError(this.handleError)
        catchError((err: HttpErrorResponse) => {
          return this.handleError(err);
          // return throwError('Something bad happened; please try again later. '+ err.message);
        })
      );

  }


  public post(url:string, param : {}) : Observable<any> {

    // let httpPraram = new HttpParams();
    // for(var x in param){
    //   httpPraram = httpPraram.append(x,param[x]);
    // }

    // console.log("this.getHeaders()", this.getHeaders());

    return this.http.post(url,   param, {headers : this.getHeaders()})
      .pipe(
        // retry(3),
        catchError((err: HttpErrorResponse) => {
          return this.handleError(err);
          // return throwError('Something bad happened; please try again later. '+ err.message);
        })
      );

  }



  public put(url:string, param : {}) : Observable<any> {

    // let httpPraram = new HttpParams();
    // for(var x in param){
    //   httpPraram = httpPraram.append(x,param[x]);
    // }

    return this.http.put<any>(url, param, {headers : this.getHeaders()})
      .pipe(
        // retry(3),
        catchError((err: HttpErrorResponse) => {
          return this.handleError(err);
          // return throwError('Something bad happened; please try again later. '+ err.message);
        })
      );


  }


  public patch(url:string, param : {}) : Observable<any> {

    // let httpPraram = new HttpParams();
    // for(var x in param){
    //   httpPraram = httpPraram.append(x,param[x]);
    // }

    return this.http.patch<any>(url, param, {headers : this.getHeaders()})
      .pipe(
      // retry(3),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
        // return throwError('Something bad happened; please try again later. '+ err.message);
      })
    );
  }



  public delete(url:string) : Observable<any> {

    // let httpPraram = new HttpParams();
    // for(var x in param){
    //   httpPraram = httpPraram.append(x,param[x]);
    // }

    return this.http.delete<any>(url, {headers : this.getHeaders()})
      .pipe(
        // retry(3),
        catchError((err: HttpErrorResponse) => {
          return this.handleError(err);
          // return throwError('Something bad happened; please try again later. '+ err.message);
        })
      );
  }


  test(){

    // console.log("Test");
  }


  private handleError(err: HttpErrorResponse) {
    // console.log("Begin", err);
    if (err.error instanceof ProgressEvent) {
      // Network Error
      alert('Please check your network connection or make sure server is alive');
    }
    else if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      alert('An error occurred: '+ err.error.message);
    }
    else{
      if(err.status == 400){

        // console.log("Bad Request : Some validations might not be fulfill");
      }
      else if(err.status == 301){
        alert("You will be logged out.");
        this.auth.logout();
      }
      else if(err.status == 401){
        alert("Token has expired , You will be logged out.");
        this.auth.logout();
      }
      else if(err.status == 403){
        alert("Forbidden : You are trying to surf banned routes, You will be logged out.");
        this.auth.logout();
      }
      else if (err.status == 500){
        alert("Server side error, Please contact support team.");
      }
    }
    //this.auth.logout();
    return throwError( err);
  }
}
