import { AuthService } from '../_services/auth/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {


  constructor(private _authService: AuthService, private _router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this._authService.isLoggednIn()) {
      if(next.data.login){
        this._router.navigate(['/pages/home']);
        return false;
      }
      return true;
    }

    if(!next.data.login){
      this._router.navigate(['']);
    }
    else{
      return true;
    }
    return false;
  }

}
