import {Component, Input, OnInit} from '@angular/core';
import {Student} from "../../../../_models/student";
import {TimeLineService} from "../../../../_services/main/time-line.service";
import {TimeLineTask} from "../../../../_models/time-line";

@Component({
  selector: 'app-guidance-time-line',
  templateUrl: './guidance-time-line.component.html',
  styleUrls: ['./guidance-time-line.component.scss']
})
export class GuidanceTimeLineComponent implements OnInit {



  @Input() student: Student;

  time_line_tasks : TimeLineTask[] = [];

  constructor(
    private service : TimeLineService
  ) { }

  ngOnInit() {
    this.loadTimeLine();
  }

  loadTimeLine(){
    this.service.get(this.student.id)
      .subscribe(data=>{
        this.time_line_tasks = data;
      })
  }



}
