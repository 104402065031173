import { Injectable } from '@angular/core';
import {API_URL} from "../../_shared/API_URL.const";
import {ApiService} from "../api.service";
import {Observable} from "rxjs";
import {Permission, Role, User} from "../../_models/user-mgt";

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(
    private api : ApiService
  ) { }

  public loadTable(params): Observable<any>{
    return this.api.post(API_URL.ROLE.DATA_TABLE, params);
  }

  publicGetAllPermissions(): Observable<any[]>{
    return this.api.get(API_URL.SELECT.PERMISSIONS, [])
  }


  publicGetPermissions($id): Observable<any>{
    return this.api.get(API_URL.ROLE.GET+'/'+$id+'/permissions', []);
  }


  update(id: number, value: any[]) : Observable<Role>{
    return  this.api.put(API_URL.ROLE.PUT+"/"+id, value);
  }

  save(value: any[]) : Observable<Role>{
    return  this.api.post(API_URL.ROLE.POST, value);
  }

  assignPermissions(user, param = []): Observable<any> {
    return this.api.put(API_URL.ROLE.PERMISSIONS+"/"+user.id+"/permissions", param);
  }


}
