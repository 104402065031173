import { NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import { UserDraftComponent } from './user-draft/user-draft.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
  AutoCompleteModule,
  ButtonModule, CalendarModule, ChartModule,
  CheckboxModule, DropdownModule, InputTextareaModule,
  InputTextModule,
  MultiSelectModule,
  ScrollPanelModule, SelectButtonModule,
  SidebarModule
} from "primeng/primeng";
import {
  NbAccordionModule,
  NbActionsModule,
  NbButtonModule,
  NbCardModule, NbIconModule, NbLayoutModule,
  NbSpinnerModule,
  NbStepperModule, NbTabsetModule, NbUserModule
} from "@nebular/theme";
import { RoleDraftComponent } from './role-draft/role-draft.component';
import { MasterHeaderDraftComponent } from './master-header-draft/master-header-draft.component';
import { MasterDetailDraftComponent } from './master-detail-draft/master-detail-draft.component';
import { GuidanceTimeLineComponent } from './student-activities/guidance-time-line/guidance-time-line.component';
import {MglTimelineModule} from "angular-mgl-timeline";
import { StudentProfileComponent } from './student-profile/student-profile.component';
import { EducationDraftComponent } from './student-profile/education-draft/education-draft.component';

import { OrderModule } from 'ngx-order-pipe';
import { HigherEducationDraftComponent } from './student-profile/higher-education-draft/higher-education-draft.component';
import { CareerKeyComponent } from './student-profile/career-key/career-key.component';
import { StudentActivitiesComponent } from './student-activities/student-activities.component';
import { GuidancePathComponent } from './student-activities/guidance-path/guidance-path.component';
import {BrowserModule} from "@angular/platform-browser";
import { DraftPathComponent } from './student-activities/guidance-path/draft-path/draft-path.component';
import {FilterPipeModule} from "ngx-filter-pipe";
import { TaskEditComponent } from './student-activities/guidance-path/task-edit/task-edit.component';
import { ChildTaskEditComponent } from './student-activities/guidance-path/child-task-edit/child-task-edit.component';
import {ImageCropperModule} from "ngx-image-cropper";

@NgModule({
  declarations: [
    UserDraftComponent,
    RoleDraftComponent,
    MasterHeaderDraftComponent,
    MasterDetailDraftComponent,
    GuidanceTimeLineComponent,
    StudentProfileComponent,
    EducationDraftComponent,
    HigherEducationDraftComponent,
    CareerKeyComponent,
    StudentActivitiesComponent,
    GuidancePathComponent,
    DraftPathComponent,
    TaskEditComponent,
    ChildTaskEditComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    NbButtonModule,
    NbCardModule,
    NbStepperModule,
    MultiSelectModule,
    ScrollPanelModule,
    NbAccordionModule,
    CheckboxModule,
    ButtonModule,
    SidebarModule,
    NbActionsModule,
    NbSpinnerModule,
    DropdownModule,
    AutoCompleteModule,
    MglTimelineModule,
    FormsModule,
    CalendarModule,
    NbTabsetModule,
    NbUserModule,
    NbIconModule,
    OrderModule,
    ChartModule,
    FilterPipeModule,
    NbLayoutModule,
    InputTextareaModule,
    ImageCropperModule,
    SelectButtonModule,
  ],
  entryComponents: [UserDraftComponent],
  exports: [
    UserDraftComponent,
    RoleDraftComponent,
    MasterHeaderDraftComponent,
    MasterDetailDraftComponent,
    GuidanceTimeLineComponent,
    StudentProfileComponent,
    StudentActivitiesComponent,
    GuidancePathComponent
  ],
  providers: [DatePipe]
})
export class SmartComponentModule { }
