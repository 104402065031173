import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {RoleService} from "../../../_services/main/role.service";
import {NbStepperComponent, NbToastrService} from "@nebular/theme";
import {AsyncValidationService} from "../../../_services/async-validation.service";
import {Permission, Role} from "../../../_models/user-mgt";
import {MENU_ITEMS} from "../../MENU_ITEMS.const";
import {Observable} from "rxjs";
import {debounceTime, map} from "rxjs/operators";
import {MasterHeader} from "../../../_models/master";
import {MasterHeaderService} from "../../../_services/main/master-header.service";

@Component({
  selector: 'app-master-header-draft',
  templateUrl: './master-header-draft.component.html',
  styleUrls: ['./master-header-draft.component.scss']
})
export class MasterHeaderDraftComponent implements OnInit {

  draftForm : FormGroup;

  @Input() draft: MasterHeader;
  @Input() is_edit: boolean;
  @Output() saveEvent = new EventEmitter<any>();

  f_submitted: boolean = false;

  rd_loading: boolean = false;

  constructor(
    private formBuilder : FormBuilder,
    private service : MasterHeaderService,
    private toastrService : NbToastrService,
    private validateService : AsyncValidationService,
  ) { }

  ngOnInit() {
    this.initForm();
  }
  get fDetails() { return this.draftForm.controls; }

  initForm(){
    this.draftForm = this.formBuilder.group({
      code: ["", Validators.required,  this.validateMasterHeaderViaServer.bind(this)],
      name: ["", Validators.required],
    });
  }



  draftDetailsFormSubmit() {
    if(this.is_edit){
      this.edit();
    }
    else{
      this.save();
    }
  }

  private edit() {

    this.rd_loading = true;
    this.service.update(this.draft.id, this.draftForm.value)
      .subscribe(data=>{

          this.rd_loading = false;
          if(data){

            this.rd_loading = false;
            this.saveEvent.emit(data);

            this.toastrService.show(
              'Successfully saved',
              'Success',
              {status :'success'}
            );
          }
        },
        error=>{
          this.rd_loading = false;
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        });
  }


  private save() {
    this.rd_loading = true;
    this.service.save(this.draftForm.value)
      .subscribe(data=>{
          this.rd_loading = false;
          if(data){
            this.rd_loading = false;
            this.saveEvent.emit(data);

            this.toastrService.show(
              'Successfully saved',
              'Success',
              {status :'success'}
            );
          }
        },
        error=>{
          this.rd_loading = false;
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        });
  }



  validateMasterHeaderViaServer({value}: AbstractControl): Observable<ValidationErrors | null> {
    return this.validateService.isMasterHeaderCodeExists(value)
      .pipe(debounceTime(500), map((emailExists: boolean) => {
        if (emailExists) {
          return {
            codeExists: true
          };
        }
        return null;
      }));
  }


  resetForms() {
    this.draftForm.reset();
  }

  setDraftValues() {
    // console.log("this.draft", this.draft);
    this.draftForm.setValue({
      code: !this.is_edit ? '' : this.draft ? this.draft.code : '' ,
      name: this.draft ? this.draft.name : '',
    });

    if(this.is_edit){
      this.draftForm.controls["code"].reset(this.draft.code);
      this.draftForm.controls["code"].disable();
    }
    else{
      this.draftForm.controls["code"].enable();
    }
  }
}
