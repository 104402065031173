import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {API_URL} from "../../_shared/API_URL.const";
import {ApiService} from "../api.service";

@Injectable({
  providedIn: 'root'
})
export class TimeLineService {

  constructor(
    private api: ApiService
  ) { }

  get(student_id) : Observable<any>{
    return this.api.get(`${API_URL.TIME_LINE.STUDENT}/${student_id}`);
  }
}
