import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "./api.service";
import {API_URL} from "../_shared/API_URL.const";

@Injectable({
  providedIn: 'root'
})
export class AsyncValidationService {

  constructor(private api: ApiService) { }

  isEmailExists(email) : Observable<any>{
    return this.api.post(API_URL.VALIDATION.IS_EMAIL_EXIST, {"email":email});
  }


  isRoleNameExists(name) : Observable<any>{
    return this.api.post(API_URL.VALIDATION.IS_ROLE_NAME_EXIST, {"name":name});
  }

  isMasterHeaderCodeExists(code) : Observable<any>{
    return this.api.post(API_URL.VALIDATION.IS_MASTER_HEADER_CODE_EXIST, {"code":code});
  }

  isMasterDetailCodeExists(code, header) : Observable<any>{
    return this.api.post(API_URL.VALIDATION.IS_MASTER_DETAIL_CODE_EXIST, {"code":code, "header" : header? header.id : null});
  }

  isStudentNICExists(nic) : Observable<any>{
    return this.api.post(API_URL.VALIDATION.IS_STUDENT_NIC_EXIST, {"nic":nic});
  }

  isStudentMobileExists(mobile) : Observable<any>{
    return this.api.post(API_URL.VALIDATION.IS_STUDENT_MOBILE_EXIST, {"mobile":mobile});
  }

  isStudentEmailExists(email) : Observable<any>{
    return this.api.post(API_URL.VALIDATION.IS_STUDENT_MOBILE_EXIST, {"email":email});
  }


}
