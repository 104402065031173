import { Injectable } from '@angular/core';
import {ApiService} from "../api.service";
import {API_URL} from "../../_shared/API_URL.const";
import {BehaviorSubject, Observable} from "rxjs";
import {Student} from "../../_models/student";

@Injectable({
  providedIn: 'root'
})
export class StudentService {


  // private student = new Subject<Student>();

  private student = new BehaviorSubject(null);
  currentStudent = this.student.asObservable();

  constructor(
    private api : ApiService
  ) { }


  changeStudent(student: Student) {
    this.student.next(student);
  }



  searchByNIC(str:any) : Observable<any>{
      return this.api.post(API_URL.SELECT.STUDENT_NIC_SEARCH, {'nic':str});
  }

  getByNIC(str:any) : Observable<any>{
      return this.api.post(API_URL.STUDENT.QUERY_BY_NIC, {'nic':str});
  }

  get(id) : Observable<any>{
    return  this.api.get(`${API_URL.STUDENT.STUDENT}/${id}`)
  }


  saveBasicDetails(param : {}) : Observable<Student>{
    return this.api.post(API_URL.STUDENT.STUDENT, param);
  }

  updateBasicDetails(student_id, param : {}) : Observable<Student>{
    return this.api.put(`${API_URL.STUDENT.STUDENT}/${student_id}`, param);
  }

  saveEducationDetails(student_id, param : {}) : Observable<any>{
    return this.api.post(`${API_URL.STUDENT.STUDENT}/${student_id}/student-education`, param);
  }

  editEducationDetails(student_id, education_id, param : {}) : Observable<any>{
    return this.api.put(`${API_URL.STUDENT.STUDENT}/${student_id}/student-education/${education_id}`, param);
  }

  saveHigherEducationDetails(student_id: number, param: {}) : Observable<any>{
    return this.api.post(`${API_URL.STUDENT.STUDENT}/${student_id}/student-higher-education`, param);
  }

  editHigherEducationDetails(student_id: number, education_id: number, param: {}) : Observable<any>{
    return this.api.put(`${API_URL.STUDENT.STUDENT}/${student_id}/student-higher-education/${education_id}`, param);
  }

  deleteEducationDetails(student_id, education_id) : Observable<any>{
    return this.api.delete(`${API_URL.STUDENT.STUDENT}/${student_id}/student-education/${education_id}`);
  }

  deleteHigherEducationDetails(student_id, higher_education_id) : Observable<any>{
    return this.api.delete(`${API_URL.STUDENT.STUDENT}/${student_id}/student-higher-education/${higher_education_id}`);
  }

  saveCareerKeyResultDetails(student_id: number, param: {})  : Observable<any>{
    return this.api.post(`${API_URL.STUDENT.STUDENT}/${student_id}/career-key-result`, param);
  }

  editCareerKeyResultDetails(student_id: number, career_key_result_id: number, param: {}) : Observable<any>{
    return this.api.put(`${API_URL.STUDENT.STUDENT}/${student_id}/career-key-result/${career_key_result_id}`, param);
  }

  saveImage(student_id: number, param: {})  : Observable<any>{
    return this.api.patch(`${API_URL.STUDENT.STUDENT}/${student_id}/image`, param);
  }
}
