import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CareerKeyResult, Student, StudentHigherEducation} from "../../../../_models/student";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NbToastrService} from "@nebular/theme";
import {StudentService} from "../../../../_services/main/student.service";

@Component({
  selector: 'app-career-key',
  templateUrl: './career-key.component.html',
  styleUrls: ['./career-key.component.scss']
})
export class CareerKeyComponent implements OnInit {

  @Input() career_key : CareerKeyResult;
  @Input() student : Student;
  @Output() saveEvent = new EventEmitter<any>();

  studentCareerKeyForm : FormGroup;
  fr_submitted = false;
  sbd_loading = false;

  constructor(
    private formBuilder : FormBuilder,
    private toastrService : NbToastrService,
    private service : StudentService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm(){
    this.studentCareerKeyForm = this.formBuilder.group({
      key_r : ['', Validators.required],
      key_i : ['', Validators.required],
      key_a : ['', Validators.required],
      key_s : ['', Validators.required],
      key_e : ['', Validators.required],
      key_c : ['', Validators.required],
      comment : ['', ],
    });
  }

  get fStudentCKRetails() { return this.studentCareerKeyForm.controls; }


  setValues(){
    if(this.career_key){
      this.studentCareerKeyForm.reset({
        key_r : this.career_key.key_r,
        key_i : this.career_key.key_i,
        key_a : this.career_key.key_a,
        key_s : this.career_key.key_s,
        key_e : this.career_key.key_e,
        key_c : this.career_key.key_c,
        comment : this.career_key.comment,
      });
    }

  }

  studentCareerKeyResultFormSubmit() {
    if(this.career_key){
      this.draftDetailsUpdate();
    }
    else{
      this.draftDetailsSave();
    }
  }

  resetForms() {
    this.studentCareerKeyForm.reset();
    this.fr_submitted = false;
    // this.fStudentCKRetails.reset();
  }





  private draftDetailsUpdate() {
    this.sbd_loading = true;
    this.service.editCareerKeyResultDetails(this.student.id, this.career_key.id, this.studentCareerKeyForm.value)
      .subscribe(data=>{

          this.sbd_loading = false;
          if(data){

            this.sbd_loading = false;
            this.saveEvent.emit(data);
            this.studentCareerKeyForm.reset();

            this.toastrService.show(
              'Successfully saved',
              'Success',
              {status :'success'}
            );
          }
        },
        error=>{
          this.sbd_loading = false;
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        });
  }

  private draftDetailsSave() {
    this.sbd_loading = true;
    this.service.saveCareerKeyResultDetails(this.student.id, this.studentCareerKeyForm.value)
      .subscribe(data=>{

          this.sbd_loading = false;
          if(data){

            this.sbd_loading = false;
            this.saveEvent.emit(data);
            this.studentCareerKeyForm.reset();
            this.toastrService.show(
              'Successfully saved',
              'Success',
              {status :'success'}
            );
          }
        },
        error=>{
          this.sbd_loading = false;
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        });
  }


}
