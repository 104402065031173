import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from "./_guards/auth-guard.service";


const routes: Routes = [
  // {path:"pages", loadChildren:'./_shared/frame/frame.module#FrameModule'},
  {path:"pages", loadChildren:'./_shared/frame/frame.module#FrameModule', canActivate: [AuthGuard]},
  {path:"", loadChildren:'./auth/auth.module#AuthModule', pathMatch:'full', canActivate: [AuthGuard], data:{login:true}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
