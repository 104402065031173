import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';

import 'dhtmlx-gantt';
import {Student, StudentHigherEducation} from "../../../../_models/student";
import {StudentService} from "../../../../_services/main/student.service";
import {DatePipe} from "@angular/common";
import {ApiService} from "../../../../_services/api.service";
import {NbToastrService} from "@nebular/theme";
import { GanttTaskData} from "../../../../_models/GanttTask";
import {ChildTaskEditComponent} from "./child-task-edit/child-task-edit.component";
import {DraftPathComponent} from "./draft-path/draft-path.component";

@Component({

  encapsulation: ViewEncapsulation.None,
  selector: 'app-guidance-path',
  templateUrl: './guidance-path.component.html',
  styleUrls: ['./guidance-path.component.scss']
})
export class GuidancePathComponent implements OnInit {

  @ViewChild("gantt_here", {static:true}) ganttContainer: ElementRef;

  @ViewChild(ChildTaskEditComponent,{static:false}) childTaskEdit:ChildTaskEditComponent;
  @ViewChild(DraftPathComponent,{static:false}) draftPath:DraftPathComponent;
  student: Student;
  // @Input() student: Student;

  is_gantt_model = false;

  data: any[] = [];
  links: any[] = [];

  selected_task: GanttTaskData;
  selected_parent_task: GanttTaskData;
  selected_parents_children_tasks: GanttTaskData[];
  is_select_parent: boolean;

  tasksAAA = {
    data:[
      {id:1, text:"BSC", start_date:"01-01-2019", duration:42, open:true, progress: 0.14, color:'#28a745', status:'success', comment: "ssssss"},
      {id:2, text:"Monitor #1", start_date:"01-01-2019", duration:6, parent:1, color:'#dc3545', status:'danger'},
      {id:3, text:"Monitor #2", start_date:"01-07-2019", duration:6, parent:1, color:'#ffc107', status:'warning'},
      {id:4, text:"Monitor #3", start_date:"01-01-2020", duration:6, parent:1, status:'info'},
      {id:5, text:"Monitor #4", start_date:"01-07-2020", duration:6, parent:1, status:'info'},
      {id:6, text:"Monitor #5", start_date:"01-01-2021", duration:6, parent:1, status:'info'},
      {id:7, text:"Monitor #6", start_date:"01-07-2021", duration:6, parent:1, status:'info'},
      {id:8, text:"Monitor #7", start_date:"01-01-2022", duration:6, parent:1, status:'info'},
      {id:9, text:"IELTS", start_date:"01-11-2022", duration:2, open:true, status:'info'},
      {id:10, text:"Monitor #1", start_date:"01-11-2022", duration:1, parent:9, status:'info'},
      {id:11, text:"Monitor #2", start_date:"01-12-2022", duration:1, parent:9, status:'info'},
      {id:12, text:"MSC", start_date:"", "unscheduled":true, duration:8, open:false, status:'info'},
      {id:13, text:"Monitor #1", start_date:"", "unscheduled":true, duration:3, parent:12, status:'info'},
      {id:14, text:"Monitor #2", start_date:"", "unscheduled":true, duration:3, parent:12, status:'info'},
      {id:15, text:"Monitor #3", start_date:"", "unscheduled":true, duration:3, parent:12, status:'info'},
      {id:16, text:"Monitor #4", start_date:"", "unscheduled":true, duration:3, parent:12, status:'info'},
      {id:17, text:"Monitor #5", start_date:"", "unscheduled":true, duration:3, parent:12, status:'info'},
      {id:18, text:"Monitor #6", start_date:"", "unscheduled":true, duration:3, parent:12, status:'info'},
      {id:19, text:"Monitor #7", start_date:"", "unscheduled":true, duration:3, parent:12, status:'info'},
      {id:20, text:"Monitor #8", start_date:"", "unscheduled":true, duration:3, parent:12, status:'info'},
      // {id:11, text:"MSC", start_date:"01-01-2022", duration:480, open:true},
      // {id:12, text:"Monitor #1", start_date:"01-01-2022", duration:60, parent:11},
      // {id:13, text:"Monitor #2", start_date:"01-03-2022", duration:60, parent:11},
      // {id:14, text:"Monitor #3", start_date:"01-05-2022", duration:60, parent:11},
      // {id:15, text:"Monitor #4", start_date:"01-07-2022", duration:60, parent:11},
      // {id:16, text:"Monitor #5", start_date:"01-09-2022", duration:60, parent:11},
      // {id:17, text:"Monitor #6", start_date:"01-11-2022", duration:60, parent:11},
      // {id:18, text:"Monitor #7", start_date:"01-01-2023", duration:60, parent:11},
      // {id:19, text:"Monitor #8", start_date:"01-03-2023", duration:60, parent:11},
    ],
    links:[
      {"id":1, "source":1, "target":9, "type":"0"},
      {"id":2, "source":1, "target":12, "type":"0"}
    ]
  };


  is_sidebar_draft_path: boolean;
  is_sidebar_task_edit: boolean;
  draft_education_title: any;
  is_draft_edit: boolean;
  draft_education: StudentHigherEducation;

  draft_task_edit_title: any;


  constructor(
    private studentService: StudentService,
    public datepipe: DatePipe,
    private api: ApiService,
    private service: StudentService,
    private toastrService: NbToastrService,
  ) {
  }

  ngOnInit() {

    this.studentService.currentStudent.subscribe(st=>{
      this.student = st;
      this.drawGantt();
    });
  }

  drawGantt(){
    gantt.config.scale_unit = "month";
    gantt.config.duration_unit = "month";
    gantt.config.step = 6;
    gantt.config.date_scale = "%M, %Y";
    gantt.templates.grid_row_class = function(start, end, task){
      return 'row-'+task.status;
    };
    gantt.templates.task_row_class  = function(start, end, task){
      return task.status;
    };
    gantt.attachEvent("onBeforeTaskDrag", function(id, mode, e){
      return false;
    });
    gantt.attachEvent("onTaskDblClick", function(id,e){
      //any custom logic here
      return false;
    });

    gantt.config.columns = [
      {name:"text",       label:"Task name",  width:"*", tree:true},
      {name:"start_date", label:"Start time", align:"center" },
      {name:"duration",   label:"Duration",   align:"center" },
    ];



    gantt.attachEvent("onTaskSelected", function(id) {

      return false;

      //Lightbox config
      let task = gantt.getTask(id);
      // console.log("--------", task);
      if(task.parent === 0){

        gantt.config.lightbox.sections = [
          {name:"time", single_date: true, height:72, type:"duration", map_to:"start_date"}
        ];
        gantt.locale.labels["gantt_start_btn"] = "Start";
        gantt.locale.labels["gantt_cancel"] = "Cancel";
        gantt.config.buttons_left = ["gantt_start_btn"];
        gantt.config.buttons_right = ["gantt_cancel"];
        gantt.showLightbox(id);
      }
      else{
        gantt.config.lightbox.sections = [
          {name:"description", height:70, map_to:"comment", type:"textarea",focus:true}
        ];

        gantt.locale.labels.section_description = "Comment";
        gantt.locale.labels["gantt_hold_btn"] = "Hold";
        gantt.locale.labels["gantt_monitor_btn"] = "Monitor";
        gantt.locale.labels["gantt_cancel"] = "Cancel";
        gantt.config.buttons_left = ["gantt_monitor_btn", "gantt_hold_btn"];
        gantt.config.buttons_right = ["gantt_cancel"];

        console.log("***********", task.comment);
        // gantt.getLightboxSection('description').setValue(task.comment);
        gantt.showLightbox(id);
      }


    });

    gantt.attachEvent("onLightboxButton", function(button_id, node, e){

      return false;
      gantt.resetLightbox();
      var id = gantt.getState().lightbox;
      var current_task = gantt.getTask(id);
      var current_task_parent = null;
      if(current_task.parent !== 0){
        current_task_parent = gantt.getTask(gantt.getParent(id));
      }
      if(button_id == "gantt_hold_btn"){
        current_task.comment = gantt.getLightboxSection('description').getValue();
        current_task.action = 'hold';
        current_task.status = 'disabled';
        current_task.color = '#c5cee0';

        if(current_task_parent){
          current_task_parent.action = 'hold';
          current_task_parent.status = 'disabled';
          current_task_parent.color = '#c5cee0';

          gantt.getChildren(current_task_parent.id).forEach((value,index)=>{
            let tsk = gantt.getTask(value);
            if(tsk.id > id){
              gantt.deleteTask(tsk.id);
            }
          });
        }



        gantt.refreshData();
        gantt.hideLightbox();
        gantt.resetLightbox();
      }
      if(button_id == "gantt_monitor_btn"){
        current_task.comment = gantt.getLightboxSection('description').getValue();
        current_task.action = 'monitor';
        current_task.status = 'success';
        current_task.color = '#28a745';
        var i_all = 0;
        var i_moniter = 0;
        if(current_task_parent){
          gantt.getChildren(current_task_parent.id).forEach((value,index)=>{
            i_all++;
            let tsk = gantt.getTask(value);
            if(tsk.id > id){
              i_moniter++;
            }
          });
          current_task_parent.progress = 1-(i_moniter/i_all);
        }

        gantt.refreshData();
        gantt.hideLightbox();
        gantt.resetLightbox();
      }
      if(button_id == "gantt_start_btn"){
        let st_date = gantt.getLightboxSection('time').getValue();
        let next_st_dt = st_date.start_date;
        let parent = gantt.getTask(id);
        parent.start_date = next_st_dt;
        parent.end_date = gantt.date.add(next_st_dt, parent.duration, 'month');
        parent.open = 1;
        parent.unscheduled = 0;
        parent.color = '#28a745';
        parent.status = 'success';
        parent.action = 'start';
        let children = gantt.getChildren(id);
        children.forEach((value,index)=>{

          let tsk = gantt.getTask(value);
          tsk.start_date = next_st_dt;
          next_st_dt = gantt.date.add(next_st_dt, tsk.duration, 'month');
          tsk.end_date = next_st_dt;
          tsk.open = 1;
          tsk.unscheduled = 0;
        });

        gantt.refreshData();
        gantt.hideLightbox();
        gantt.resetLightbox();
      }
      if(button_id == "gantt_cancel"){
        gantt.hideLightbox();
        gantt.resetLightbox();
      }
    });



    gantt.init(this.ganttContainer.nativeElement);

    this.links = [];
    this.data = [];
    if(this.student && this.student.gantt_tasks){
      this.student.gantt_tasks.forEach( (t, i)=> {

        let img_html = '';
        // if(!t.parent && t.action == 'completed'){
        if(t.type == 1){
          img_html = ' <img style="vertical-align: middle;" src="../../../../../assets/no_issue.svg" height="20px"/>';
        }
        else if(t.type == 2){
          img_html = ' <img style="vertical-align: middle;" src="../../../../../assets/issue.svg" height="20px"/>';
        }
        else if(t.type == 3){
          img_html = ' <img style="vertical-align: middle;" src="../../../../../assets/solve.svg" height="20px"/>';
        }

        // }
        this.data.push({
          id: t.id,
          student_id: t.student_id,
          course_id: t.course_id,
          text: t.problem? img_html+' '+t.problem.name : img_html+' '+t.text,
          start_date: this.formatDate(t.start_date),
          end_date: this.formatDate(t.end_date),
          duration: t.duration,
          open: t.open,
          progress: t.progress,
          status: t.status,
          parent: t.parent,
          unscheduled: t.unscheduled,
          comment: t.comment,
          action: t.action,
          type: t.type,
          problem: t.problem,
          user: t['updated_user'] ? t['updated_user']['calling_name'] : t['created_user']['calling_name'],
          user_pic: t['updated_user'] ? t['updated_user'].pic : t['created_user'].pic,
          date_time: t['updated_at'] ? t['updated_at'] : t['created_at'],
          color: this.getStatusColorCode(t.status)
        });
      });

      this.student.gantt_task_links.forEach( (l, i)=> {
        this.links.push({
          id: l.id,
          student_id: l.student_id,
          source: l.source,
          target: l.target,
          type: l.type

        });
      });
    }


    gantt.clearAll();
    gantt.resetLightbox();
    gantt.parse({data: this.data, links: this.links});
  }


  formatDate(date){
    let date1 = new Date();
    return this.datepipe.transform(date, 'dd-MM-yyyy');
  }


  getStatusColorCode(status: string) : string{
    if(status === 'success'){
      return '#28a745';
    }
    else if(status === 'warning'){
      return '#ffc107';
    }
    else if(status === 'danger'){
      return '#dc3545';
    }
    else if(status === 'disabled'){
      return '#c5cee0';
    }
    else {
      return '#17a2b8';
    }
  }

  sideBarTaskEditShowEvent() {
    // console.log("Child task show event", this.is_select_parent, this.selected_task);
    if(!this.is_select_parent){
      this.childTaskEdit.setValues();
    }
  }

  sideBarTaskEditCloseEvent() {
    this.drawGantt();
  }

  sideBarDraftCloseEvent() {
    this.drawGantt();
  }

  sideBarDraftShowEvent() {
    this.draftPath.loadBeforeCourses();
  }

  toggleDraft() {
    this.is_sidebar_draft_path = !this.is_sidebar_draft_path;
  }

  toggleTaskEdit() {
    this.is_sidebar_task_edit = !this.is_sidebar_task_edit;
  }

  initEducationDraftSideBar(education: StudentHigherEducation, is_new : boolean) {

    this.draft_education = education;
    if(!is_new){
      this.is_draft_edit = true;
      this.draft_education_title = "Edit Course  [ "+education.course+" ]";
    }
    else{
      this.is_draft_edit = false;
      this.draft_education_title = 'Add New Course';
    }

    this.toggleDraft();
  }

  saveGantt() {
    let t = gantt.getTaskByTime();
    let selected_task_id = gantt.getSelectedId();
    if(selected_task_id){
      this.is_select_parent = false;
      this.selected_task = gantt.getTask(selected_task_id);
      let ch_ids
      if(this.selected_task.parent){
        this.is_select_parent = false;
        this.selected_parent_task = gantt.getTask(this.selected_task.parent);
        ch_ids = gantt.getChildren(this.selected_parent_task.id);
        // this.draft_task_edit_title = this.selected_task.text + ' in ' + this.selected_parent_task.text;
      }
      else{
        this.is_select_parent = true;
        this.selected_parent_task = this.selected_task;
        ch_ids = gantt.getChildren(this.selected_task.id);
        // this.draft_task_edit_title = this.selected_task.text;
      }
      this.selected_parents_children_tasks = [];
      ch_ids.forEach( e => {
        this.selected_parents_children_tasks.push(gantt.getTask(e));
      });
      this.toggleTaskEdit();
    }
    else {
      this.toastrService.show(
        'Please select a task to this operation',
        'No task selected',
        {status :'info', icon: 'bell-outline'}
      );
    }
  }

  emitChildTaskEdit($event){
    this.reloadStudent();
    this.toggleTaskEdit();
  }
  emitTaskEdit($event){
    this.reloadStudent();
    this.toggleTaskEdit();
  }
  emitDraftPath($event){
    this.reloadStudent();
    this.toggleDraft();
  }

  reloadStudent() {
      this.studentService.get(this.student.id)
        .subscribe(data=>{
          this.studentService.changeStudent(data);

        });
  }
}

