import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {API_URL} from "../../_shared/API_URL.const";
import {ApiService} from "../api.service";

@Injectable({
  providedIn: 'root'
})
export class GanttService {

  constructor(
    private api: ApiService
  ) { }


  start(id: number, params:{}): Observable<any>{
    return this.api.put(`${API_URL.GANTT.TASK}/${id}/start`, params);
  }


  monitor(id: number, params:{}): Observable<any>{
    return this.api.put(`${API_URL.GANTT.TASK}/${id}/monitor`, params);
  }

  hold(id: number, params:{}): Observable<any>{
    return this.api.put(`${API_URL.GANTT.TASK}/${id}/hold`, params);
  }

  solve(id: number, params:{}): Observable<any>{
    return this.api.put(`${API_URL.GANTT.TASK}/${id}/solve`, params);
  }

}
