import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {MasterDetail, MasterHeader} from "../../../_models/master";
import {MasterHeaderService} from "../../../_services/main/master-header.service";
import {NbToastrService} from "@nebular/theme";
import {AsyncValidationService} from "../../../_services/async-validation.service";
import {Observable} from "rxjs";
import {debounceTime, map} from "rxjs/operators";
import {SelectService} from "../../../_services/utils/select.service";
import {MasterDetailService} from "../../../_services/main/master-detail.service";

@Component({
  selector: 'app-master-detail-draft',
  templateUrl: './master-detail-draft.component.html',
  styleUrls: ['./master-detail-draft.component.scss']
})
export class MasterDetailDraftComponent implements OnInit {

  draftForm : FormGroup;

  @Input() draft: MasterDetail;
  @Input() is_edit: boolean;
  @Output() saveEvent = new EventEmitter<any>();

  f_submitted: boolean = false;

  rd_loading: boolean = false;
  select_option_master_headers: any[] = [];
  selectedMasterHeader: any = null;
  select_option_master_parent: MasterDetail[] = [];
  selectedMasterParent: MasterDetail | null;
  // select_option_master_headers: MasterHeader;

  constructor(
    private formBuilder : FormBuilder,
    private service : MasterDetailService,
    private toastrService : NbToastrService,
    private validateService : AsyncValidationService,
    private selectService : SelectService
    ) { }

  ngOnInit() {
    this.initForm();
    this.getHeaders();
  }
  get fDetails() { return this.draftForm.controls; }

  initForm(){
    this.draftForm = this.formBuilder.group({
      header: ["", Validators.required],
      code: ["", Validators.required,  this.validateMasterDetailViaServerOnCode.bind(this)],
      name: ["", Validators.required],
      parent: ["", ],
      add_double1: ["",],
      add_double2: ["",],
      add_double3: ["",],
      add_str1: ["", ],
      add_str2: ["", ],
      add_str3: ["", ],
      add_text: ["", ],
      status: ["", ],
    });
  }

  getHeaders(){
    this.selectService.masterHeaders()
      .subscribe(
        data=>{
          this.select_option_master_headers = [];
          if (data.length > 0) {
            for (let d of data) {
              this.select_option_master_headers.push({ label: `${d.code} | ${d.name}`, value: d })
            }
          }
        }
      )
  }




  draftDetailsFormSubmit() {
    if(this.is_edit){
      this.edit();
    }
    else{
      this.save();
    }
  }

  private edit() {

    this.rd_loading = true;
    this.service.update(this.draft.id, this.draftForm.value)
      .subscribe(data=>{

          this.rd_loading = false;
          if(data){

            this.rd_loading = false;
            this.saveEvent.emit(data);

            this.toastrService.show(
              'Successfully saved',
              'Success',
              {status :'success'}
            );
          }
        },
        error=>{
          this.rd_loading = false;
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        });
  }


  private save() {
    this.rd_loading = true;
    this.service.save(this.draftForm.value)
      .subscribe(data=>{
          this.rd_loading = false;
          if(data){
            this.rd_loading = false;
            this.saveEvent.emit(data);

            this.toastrService.show(
              'Successfully saved',
              'Success',
              {status :'success'}
            );
          }
        },
        error=>{
          this.rd_loading = false;
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        });
  }



  validateMasterDetailViaServerOnCode({value}: AbstractControl): Observable<ValidationErrors | null> {
    return this.validateService.isMasterDetailCodeExists(value, this.selectedMasterHeader)
      .pipe(debounceTime(500), map((emailExists: boolean) => {
        if (emailExists) {
          return {
            codeExists: true
          };
        }
        return null;
      }));
  }


  resetForms() {
    this.draftForm.reset();
  }

  setDraftValues() {
    // console.log("this.draft", this.draft);
    this.draftForm.setValue({
      code: !this.is_edit ? '' : this.draft.code ,
      name: this.draft ? this.draft.name : '',
      status: this.draft ? this.draft.status : '',
      add_double1: this.draft ? this.draft.add_double1 : '',
      add_double2: this.draft ? this.draft.add_double2 : '',
      add_double3: this.draft ? this.draft.add_double3 : '',
      add_str1: this.draft ? this.draft.add_str1 : '',
      add_str2: this.draft ? this.draft.add_str2 : '',
      add_str3: this.draft ? this.draft.add_str3 : '',
      add_text: this.draft ? this.draft.add_text : '',
      header: this.draft ? this.draft.header : null,
      parent: null,
    });
    //
    if(this.is_edit){
      this.draftForm.controls["code"].disable();
      this.draftForm.controls["header"].disable();
      this.draftForm.controls["code"].reset(this.draft.code);
    }
    else{
      this.draftForm.controls["code"].enable();
      this.draftForm.controls["header"].enable();
    }
    if(this.draft){
      // this.selectedMasterHeader = this.draft.header;
      if(this.draft.parent){
        this.select_option_master_parent.push(this.draft.parent);
        this.selectedMasterParent = this.draft.parent
        // console.log("AAAAAAAAAAAAAAAAAAAAAAAAAselect_option_master_parent ", this.selectedMasterParent );
      }
    }


  }


  searchParent($event: any) {
    this.service.searchByString($event.query, null)
      .subscribe(
        data=>{

          this.select_option_master_parent = data;
        }

      )
  }

  selectHeaderCode() {
    this.draftForm.controls["code"].updateValueAndValidity();

  }
}
