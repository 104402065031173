import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MENU_ITEMS } from '../../_shared/MENU_ITEMS.const';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private _router: Router,
    ) { }



  setAuthToken(token) {
    localStorage.setItem("_Token", token);
  }


  setCompany(company) {
    localStorage.setItem("_Company", company);
  }


  setPermissions(permissions){
    localStorage.setItem("_Permissions", permissions);
  }



  unique(list) {
    var result = [];

    for (var i = list.length - 1; i >= 0; i--) {
      if (result.indexOf(list[i]) == -1) {
        result.push(list[i]);
      }
    }

    return result;
  }


  getSideBarItems(){

    if(!this.isLoggednIn()){
      return [];
    }
    let array = [];
    let pris = this.getPermissions();
    if(pris){
      for(let p of pris){
        array.push(p.split('-',1)[0]);
      }
    }


    array = this.unique(array);

    let return_items_array = [];
    return_items_array.push(MENU_ITEMS[0]);

    for(let itm of MENU_ITEMS ){
      if(array.indexOf(itm.code) !==-1 ){
        return_items_array.push(itm);
      }
    }

    return_items_array.push(MENU_ITEMS[1]);
    return_items_array.push(MENU_ITEMS[2]);

    return return_items_array;
  }


  public logout(){

    localStorage.clear();
    this._router.navigate(['']);

  }

  checkPermission(permission_string){
    let permission_array = [];
    if(permission_string){
      permission_array = permission_string.split("|", 15);
    }
    let permissions = this.getPermissions();
    for(let p of permission_array){
      if(permissions.indexOf(p) !== -1){
        return true;
      }
    }
    return false;
  }


  getAuthToken() : string {
    return localStorage.getItem("_Token");
  }

  getCompany() : string {
    return JSON.parse(localStorage.getItem("_Company"));
  }


  getPermissions() {
    return JSON.parse(localStorage.getItem("_Permissions"));
  }


  isLoggednIn() : boolean {
    // return false;
    return this.getAuthToken() !== null;
  }



}


