import { Injectable } from '@angular/core';
import {ApiService} from "../api.service";
import { API_URL } from "../../_shared/API_URL.const"
import {Role, User} from "../../_models/user-mgt";
import {Observable} from "rxjs";

@Injectable()
export class UserService {

  constructor(private api: ApiService) { }

  public loadTable(params){
    return this.api.post(API_URL.USER.DATA_TABLE, params);
  }

  public terminateDevice($id) {
    return this.api.patch(API_URL.USER.TERMINATE_DEVICE + '/' + $id, []);
  }

  public saveUser(user : User) : Observable<User>{
    return this.api.post(API_URL.USER.POST, user);
  }

  public updateUser(id, user : User) : Observable<User>{
    return  this.api.put(API_URL.USER.PUT+"/"+id, user);
  }

  public assignRoles(user : User, roles: Role[]){
    return this.api.patch(`${API_URL.USER.ATTACHE_ROLES}/${user.id}/roles`, roles)
  }

  public changeStatus(user : User, status) : Observable<User>{
    return this.api.patch(`${API_URL.USER.ATTACHE_ROLES}/${user.id}/change-status`, status);
  }


}

