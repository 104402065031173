import {Component, OnInit} from '@angular/core';
import {Student} from "../../../_models/student";

@Component({
  selector: 'app-student-activities',
  templateUrl: './student-activities.component.html',
  styleUrls: ['./student-activities.component.scss']
})
export class StudentActivitiesComponent implements OnInit {

  student: Student;
  constructor(
  ) {
  }

  ngOnInit() {
  }


  studentLoaded() {
    // this.gud.drawGantt();

  }

}
