import { Component } from '@angular/core';
import {ProfileService} from "./_services/profile.service";
import {AuthService} from "./_services/auth/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'frontend';
  constructor (
    private profileService : ProfileService,
    private auth : AuthService
  ){
    if(this.auth.isLoggednIn()){
      this.profileService.setProfile();
    }
  }
}
