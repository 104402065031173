import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GanttTaskData} from "../../../../../_models/GanttTask";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {GanttService} from "../../../../../_services/main/gantt.service";
import {NbToastrService} from "@nebular/theme";

@Component({
  selector: 'app-task-edit',
  templateUrl: './task-edit.component.html',
  styleUrls: ['./task-edit.component.scss']
})
export class TaskEditComponent implements OnInit {

  @Input() task: GanttTaskData;
  @Input() parent: GanttTaskData;
  @Input() parents_children: GanttTaskData[];
  @Input() is_parent: boolean;
  @Output() saveEvent = new EventEmitter<any>();

  projectStartForm: FormGroup;
  _loading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private service: GanttService,
    private toastrService: NbToastrService
  ) {
    this.initProjectStartForm();
  }

  ngOnInit() {
  }

  initProjectStartForm(){
    this.projectStartForm = this.formBuilder.group({
      start_date: ['', Validators.required]
    });
  }

  projectStartFormSubmit(id) {
    this.service.start(id, this.projectStartForm.value)
      .subscribe(data=>{
          this.saveEvent.emit(true);
        this.toastrService.show(
          'Successfully saved',
          'Success',
          {status :'success'}
        );
      },
        error=>{
          this.toastrService.show(
            'Something went wrong, please try again.',
            'Error',
            {status :'danger'}
          );
        });
  }
}
