import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbThemeModule,
  NbLayoutModule,
  NbCardModule,
  NbToastrService,
  NbToastrModule,
  NbWindowModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { HttpClientModule, /* other http imports */ } from "@angular/common/http";
// import { PageNotFoundComponent } from './_shared/page-not-found/page-not-found.components';
import {AuthGuard} from "./_guards/auth-guard.service";
import {SidebarModule} from "primeng/primeng";
import {SmartComponentModule} from "./_shared/smart-component/smart-component.module";


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({name: 'default'}),
    NbLayoutModule,
    NbEvaIconsModule,
    HttpClientModule,
    NbCardModule,
    NbToastrModule.forRoot(),
    SmartComponentModule,
    SidebarModule

  ],
  providers: [AuthGuard,NbToastrService],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
