// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//const API_BASE_URL = "https://localhost:8000/v1/";
const API_BASE_URL = 'https://www.ld.puttalam.dist.gov.lk/api/public/v1/';
export const environment = {
  production: false,
  API_URL: {
    AUTH: {
      AUTH: `${API_BASE_URL}auth/login`,
      PASSWORD_CHANGE: `${API_BASE_URL}auth/change-password`,
      PERMISSIONS: `${API_BASE_URL}user-permissions`,
      LOGOUT: `${API_BASE_URL}auth/logout`,
    },
    SELECT: {
      ROLES: `${API_BASE_URL}select/roles`,
      PERMISSIONS: `${API_BASE_URL}select/permissions`,
      MASTER_HEADER: `${API_BASE_URL}select/master-headers`,
      MASTER_HEADER_DETAILS: `${API_BASE_URL}select/master-headers`, //%d/master-details
      MASTER_DETAIL_SEARCH: `${API_BASE_URL}select/master-detail/search`,
      STUDENT_NIC_SEARCH: `${API_BASE_URL}select/student/search`,
    },
    VALIDATION : {
      IS_EMAIL_EXIST : `${API_BASE_URL}validation/user/email`,
      IS_ROLE_NAME_EXIST : `${API_BASE_URL}validation/role/name`,
      IS_MASTER_HEADER_CODE_EXIST : `${API_BASE_URL}validation/master-header/code`,
      IS_MASTER_DETAIL_CODE_EXIST : `${API_BASE_URL}validation/master-detail/code`,
      IS_STUDENT_NIC_EXIST : `${API_BASE_URL}validation/student/nic`,
      IS_STUDENT_MOBILE_EXIST : `${API_BASE_URL}validation/student/mobile`,
      IS_STUDENT_EMAIL_EXIST : `${API_BASE_URL}validation/student/email`,
    },
    PROFILE : {
      GET : `${API_BASE_URL}profile`,
      DETAILS : `${API_BASE_URL}profile`,
      PIC : `${API_BASE_URL}profile-pic`,
      RESET_PASSWORD : `${API_BASE_URL}reset-password`,
    },
    USER: {
      GET: `${API_BASE_URL}user`,
      GET_ALL: `${API_BASE_URL}users`,
      DATA_TABLE: `${API_BASE_URL}users-table`,
      GET_BY_USERNAME: `${API_BASE_URL}user/username`,
      GET_BY_EMAIL: `${API_BASE_URL}user/email`,
      GET_PRIVILEGES_BY_USERNAME: `${API_BASE_URL}user/username/privileges`,
      POST: `${API_BASE_URL}user`,
      PUT: `${API_BASE_URL}user`,
      PUT_PROFILE: `${API_BASE_URL}profile`,
      POST_PROFILE_PIC: `${API_BASE_URL}profile-picture`,
      CHANGE_STATUS: `${API_BASE_URL}user/change-status`,
      TERMINATE_DEVICE: `${API_BASE_URL}user/device`,
      ATTACHE_ROLES: `${API_BASE_URL}user`, //%d/role
    },
    ROLE: {
      GET: `${API_BASE_URL}role`,
      GET_ALL: `${API_BASE_URL}roles`,
      DATA_TABLE: `${API_BASE_URL}roles-table`,
      POST: `${API_BASE_URL}role`,
      PUT: `${API_BASE_URL}role`,
      CHANGE_STATUS: `${API_BASE_URL}role/change-status`,
      PERMISSIONS : `${API_BASE_URL}role`, //%d/permissions
    },
    MASTER_HEADER: {
      GET: `${API_BASE_URL}master-header`,
      GET_ALL: `${API_BASE_URL}master-headers`,
      DATA_TABLE: `${API_BASE_URL}master-headers-table`,
      POST: `${API_BASE_URL}master-header`,
      PUT: `${API_BASE_URL}master-header`,
      // CHANGE_STATUS: `${API_BASE_URL}role/change-status`,
      // PERMISSIONS : `${API_BASE_URL}role`, //%d/permissions
    },
    MASTER_DETAIL: {
      GET: `${API_BASE_URL}master-detail`,
      GET_ALL: `${API_BASE_URL}master-details`,
      DATA_TABLE: `${API_BASE_URL}master-details-table`,
      POST: `${API_BASE_URL}master-detail`,
      PUT: `${API_BASE_URL}master-detail`,
      CHANGE_STATUS: `${API_BASE_URL}master-detail` , // %d/change-status`,
      // CHANGE_STATUS: `${API_BASE_URL}role/change-status`,
      // PERMISSIONS : `${API_BASE_URL}role`, //%d/permissions
    },
    STUDENT : {
      QUERY_BY_NIC : `${API_BASE_URL}student/query/nic`,
      STUDENT : `${API_BASE_URL}student`,

    },
    TIME_LINE : {
      STUDENT : `${API_BASE_URL}time-line/student`

    },
    GANTT: {
      TASK: `${API_BASE_URL}gantt/task`
    }

  },





  MENU_ITEMS : [
    {
      title: 'Dashboard',
      code: 'home',
      icon: 'home-outline',
      link: '/pages/home',
      has_permission_route: true,
    },
    {
      title: 'Profile',
      code: 'profile',
      icon: 'heart-outline',
      link: '/pages/profile',
      has_permission_route: false,
    },
    {
      title: 'Calendar',
      code: 'calendar',
      icon: 'calendar-outline',
      link: '/pages/calendar',
      has_permission_route: false,
    },
    {
      title: 'User Mgt',
      icon: 'people-outline',
      code: 'userMgt',
      link: '/pages/user-management',
      has_permission_route: true,
    },
    {
      title: 'Masters',
      icon: 'book-outline',
      code: 'master',
      link: '/pages/master',
      has_permission_route: true,
    },
    {
      title: 'Guidance',
      icon: 'paper-plane-outline',
      code: 'guidance',
      link: '/pages/guidance',
      has_permission_route: true,
    },
    {
      title: 'Privacy Policy',
      icon: 'checkmark-outline',
      code: 'privacy',
      link: [],
      has_permission_route: false,
    },
    {
      title: 'Logout',
      icon: 'unlock-outline',
      code: 'logout',
      link: [],
      has_permission_route: false,
    },
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
